import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import headshot from '../images/headshot.png'
import Newsletter from '../components/Newsletter'
import Content from '../atoms/Content'
import styled from 'styled-components'

const Headshot = styled.img`
  float: left;
  border-radius: 100px;
  border: 2px solid ${props => props.theme.primaryColor};
  margin: 20px 20px 10px 10px;
`

export default function About({ data, location }) {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `python`, `javascript`, `react`]} />
      {/* <img src={headshot} alt="photo of Steven Mercatante" /> */}
      <Content>
        <h1>About Me</h1>
        <Headshot src={headshot} />
        <p>
          Hey! I'm Steven Mercatante, and I'm a full stack software engineer
          from New York. I've been professionally building things on the web for
          over 15 years, and even longer than that just for fun. I got my start
          with HTML, CSS and JavaScript back in the day, did a <em>ton</em> of
          Flash work (R.I.P) and dove deep into backend languages like PHP,
          Python, Clojure and Elixir. These days, I focus mostly on JavaScript
          and Python.
        </p>
        <p>
          I've worked for companies large and small and was a partner at an
          agency. Now, I work for myself as a consultant helping companies and
          teams build new products and systems, and improve existing ones. Check
          out my <Link to="work">work</Link> page to see my clients and
          services, and <Link to="contact">contact me</Link> if you're
          interested in working together.
        </p>
        <p>
          Besides coding and building products, I'm an avid cyclist, aspiring
          chef, love reading, and I'm really good at buying guitars and then not
          playing them.
        </p>
        <p>
          You can follow me on{' '}
          <a href="https://twitter.com/mercatante">Twitter</a> and subscribe to
          my newsletter below if you want to be notified when I've published
          something new.
        </p>
      </Content>
      <Newsletter referrer="about" marginTop={40} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
